<template>
  <div>
    <!-- picCE5B1D1101508E90932AF92FA8A88869.jpg -->
    <img class="img-large rounded" src="@/assets/news-network/lego-world-copenhagen.jpg">
    <p>
      <b>LEGO WORLD 2010</b><br>
      Copenhagen, Denmark<br>
      18th – 21st February<br>
      Bella Center<br>
      LEGOworld.dk
    </p>
    <p>
      The LEGO Universe team is really excited to be attending LEGO World in Copenhagen for a second year.
    </p>
    <p>
      LEGO World is the world’s most fun construction site, where your fantasy and creativity will have no limitations.  At LEGO World you will have the possibility to explore a world of LEGO bricks. Build your own LEGO figures, participate in exciting competitions, and explore the many cool LEGO models.  See you there!
    </p>
  </div>
</template>
